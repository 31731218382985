<script setup lang="ts">
</script>
<template>
  <div
    class="aboutus"
    id="about-us"
  >
    <div class="aboutus-content">
      <h2 class="center-title mobile">О КОМПАНИИ</h2>
      <div class="aboutus-content-plates">
        <div class="aboutus-content-plates-plate">
          <img
            class="aboutus-content-plates-plate-image"
            src="@/assets/images/svg/work-time.svg"
            alt="">
          <div class="aboutus-content-plates-plate-text">
            <p class="aboutus-content-plates-plate-text-title">>3</p>
            <p class="aboutus-content-plates-plate-text-description">Лет <br>работы</p>
          </div>
        </div>
        <div class="aboutus-content-plates-plate">
          <img
            class="aboutus-content-plates-plate-image"
            src="@/assets/images/svg/ad.svg"
            alt="">
          <div class="aboutus-content-plates-plate-text ad">
            <p class="aboutus-content-plates-plate-text-title">570+</p>
            <p class="aboutus-content-plates-plate-text-description">Рекламных <br>кампаний</p>
          </div>
        </div>
        <div class="aboutus-content-plates-plate">
          <img
            class="aboutus-content-plates-plate-image lids"
            src="@/assets/images/svg/lids.svg"
            alt="">
          <div class="aboutus-content-plates-plate-text lids">
            <p class="aboutus-content-plates-plate-text-title">2,5м</p>
            <p class="aboutus-content-plates-plate-text-description">Лидов</p>
          </div>
        </div>
      </div>
      <div class="aboutus-content-info">
        <p class="aboutus-content-info-title">JIGSAW CORP - стратегия, опыт, <br>стремление</p>
        <p class="aboutus-content-info-description">
          Наша группа рекламных компаний использует передовые
          рекламные инструменты социального трафика. <br><br>
          Разрабатываем digital стратегии, повышаем узнаваемость
          бренда, настраиваем прибыльные рекламные кампании. <br><br>
          Превращаем вашу бизнес-идею в сверкающий бриллиант
          успешного бренда.
        </p>
        <div class="aboutus-content-info-icon">
          <img
            class="aboutus-content-info-icon-image"
            src="@/assets/images/svg/info.svg"
            alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aboutus-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.aboutus {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(400px);
    @media (orientation: landscape){
      margin-top: get-vw(300px);
    }
    &-plates {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: get-vw(70px);
      width: 100%;
      @media (orientation: landscape){
        flex-direction: row;
        width: 90%;
        margin-left: get-vw(-150px);
      }
      &-plate {
        display: flex;
        flex-direction: row;
        align-items: center;
        &-image {
          width: get-vw(1200px);
          margin-right: get-vw(-10px);
          &.lids {
            margin-top: get-vw(-40px);
            width: get-vw(900px);
            margin-left: get-vw(200px);
          }
          @media (orientation: landscape){
            width: get-vw(450px);
            margin-right: get-vw(-10px);
            &.lids {
              margin-top: get-vw(-40px);
              width: get-vw(410px);
              margin-left: get-vw(1px);
            }
          }
        }
        &-text {
          display: flex;
          flex-direction: column;
          color: #2D2D2D;
          margin-left: get-vw(-100px);
          &.ad {
            margin-top: get-vw(-2px);
          }
          &.lids {
            margin-top: get-vw(-4px);
            margin-left: 0;
          }
          &-title {
            font-size: get-vw(165px);
            font-weight: 700;
          }
          &-description {
            font-size: get-vw(70px);
            font-weight: 400;
          }
          @media (orientation: landscape){
            margin-left: get-vw(-100px);
            &.ad {
              margin-top: get-vw(-2px);
            }
            &.lids {
              margin-top: get-vw(-4px);
            }
            &-title {
              font-size: get-vw(85px);
            }
            &-description {
              font-size: get-vw(40px);
            }
          }
        }
      }
    }
    &-info {
      padding: get-vw(150px);
      width: 70%;
      height: auto;
      border: get-vw(15px) solid #2E6ADB;
      border-radius: get-vw(35px);
      margin-top: get-vw(65px);
      position: relative;
      @media (orientation: landscape){
        padding: get-vw(70px);
        border: get-vw(10px) solid #2E6ADB;
        border-radius: get-vw(30px);
        margin-top: get-vw(65px);
      }
      &-icon {
        width: get-vw(200px);
        height: get-vw(200px);
        border-radius: get-vw(35px);
        position: absolute;
        top: get-vw(-100px);
        left: get-vw(-100px);
        background: #000000;
        overflow: hidden;
        &-image {
          width: get-vw(210px);
          height: get-vw(210px);
          transform: scale(1.25);
        }
        @media (orientation: landscape){
          width: get-vw(120px);
          height: get-vw(120px);
          border-radius: get-vw(30px);
          top: get-vw(-60px);
          left: get-vw(-60px);
          &-image {
            width: get-vw(130px);
            height: get-vw(130px);
          }
        }
      }
      &-title {
        font-size: get-vw(80px);
        font-weight: 700;
        @media (orientation: landscape){
          font-size: get-vw(50px);
        }
      }
      &-description {
        margin-top: get-vw(130px);
        font-size: get-vw(70px);
        @media (orientation: landscape){
          margin-top: get-vw(60px);
          font-size: get-vw(40px);
        }
      }
    }
  }
}
</style>
